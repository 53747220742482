import React, { useState, useEffect } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'
import { Window } from '../common/Window'
import { Acumin } from '../common/Acumin'
import { SpOnly } from '../common/SpOnly'
import { PcOnly } from '../common/PcOnly'

const marqueeImageSrc = require('../../images/marquee@2x.png')
const logoImageSrc = require('../../images/market_about_logo.png')

export const Home = () => {
  const [height, setHeight] = useState(1000)
  const [width, setWidth] = useState(0)
  const [showLogo, setShowLogo] = useState(false)
  const [showWindow, setShowWindow] = useState(true)

  const [email, setEmail] = useState('')

  const onTouchMove = (e) => {
    const touchobj = e.changedTouches[0]
    if (touchobj.pageX > 300) {
      setShowWindow(false)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await addToMailchimp(email, null, null)
    window.alert("ご登録ありがとうございます。\n登録されたアドレスに確認メールが届きます。\n届いていないようでしたら誤りがあるか迷惑メールに入っている可能性がございますのでお確かめください。")
  }

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setEmail(e.target.value as string)
  }

  useEffect(() => {
    const handleResize = () => {
      setHeight(0)
      setHeight(window.innerHeight)

      setWidth(0)
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    setTimeout(() => {
      setShowLogo(true)
    }, 3000)
  }, [])

  return (
    <>
      <Container>
        <FirstView height={height}>
          <LogoContainer>
            <ApostropheTopWrapper showLogo={showLogo}>
              <Image path='apostrophe_top@2x.png' />
            </ApostropheTopWrapper>
            <LogoWrapper showLogo={showLogo}>
              <Image path='home_logo@2x.png' />
            </LogoWrapper>
            <ApostropheBottomWrapper showLogo={showLogo}>
              <Image path='apostrophe_bottom@2x.png' />
            </ApostropheBottomWrapper>
          </LogoContainer>
          <ScrollImageWrapper showLogo={showLogo}>
            <Image path='scroll@2x.png' />
          </ScrollImageWrapper>
        </FirstView>

        <MarqueeWrapper>
          <MarqueeImageWrapper width={width}>
            <img src={marqueeImageSrc} alt={marqueeImageSrc} />
            <img src={marqueeImageSrc} alt={marqueeImageSrc} />
            <img src={marqueeImageSrc} alt={marqueeImageSrc} />
            <img src={marqueeImageSrc} alt={marqueeImageSrc} />
            <img src={marqueeImageSrc} alt={marqueeImageSrc} />
          </MarqueeImageWrapper>
        </MarqueeWrapper>

        <SpOnly>
          <EnTextImageWrapper>
            <Image path='en_text_sp@2x.png' />
          </EnTextImageWrapper>
        </SpOnly>

        <TextContainer>
          <WindowWrapper onTouchMove={onTouchMove} showWindow={showWindow} width={width}>
            <Window heightSp={130} widthSp={340} heightPc={194} widthPc={620}>
              <WindowContent>
                <JaText>
                  カルチャーを想う気持ちに国籍や人種の境界はない。今日、世界中のネットワークは連続して、空間を越えて共感する。STREET MINDを生きる都市々々のコミュニティをつないでファッション、アート、ミュージック、フード、空間 …すべてのカルチャーにとっての互いの意思、そしてクリエイティビティが等しく連鎖するINSPIRATION CULT MARKET今はまだ、誰も感じたことのない新しいコラボレーションが既成の境界線を超越えていく。
                </JaText>
              </WindowContent>
            </Window>
          </WindowWrapper>

          <PcOnly>
            <EnTextImageWrapper>
              <Image path='en_text_pc@2x.png' />
            </EnTextImageWrapper>
          </PcOnly>
        </TextContainer>

        <MarketLogoWrapper>
          Powered by
          <MarketLogoImageWrapper>
            <a href="https://www.inspirationcult.net/">
              <img src={logoImageSrc} alt="logo" />
            </a>
          </MarketLogoImageWrapper>
        </MarketLogoWrapper>

        <SubscribeFormContainer>
          <SubscribeForm onSubmit={handleSubmit}>
            <SubscribeFormWrapper>
              <SubscribeInput onChange={handleChange} placeholder='Your Email Address' />
              <SubscribeButton type='submit'>SUBSCRIBE</SubscribeButton>
            </SubscribeFormWrapper>
          </SubscribeForm>
        </SubscribeFormContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  overflow: hidden;
`

const FirstView = styled.div<{ height: number }>`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  height: ${props => props.height}px;
  position: relative;
`

const LogoContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const LogoWrapper = styled.div<{ showLogo: boolean }>`
  ${props => customMedia.lessThan("medium")`
    display: ${props.showLogo ? 'block' : 'none'};
    width: 300px;
  `}
  ${props => customMedia.greaterThan("medium")`
    display: ${props.showLogo ? 'block' : 'none'};
    width: 735px;
  `}

  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;

  :before, :after {
    animation: 2s cubic-bezier(.4, 0, .2, 1) forwards;
    background-color: #fff;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  :before {
    animation: logoBefore 1s forwards;
    top: 0;
    bottom: 50%;
  }

  :after {
    animation: logoAfter 1s forwards;
    top: 50%;
    bottom: 0;
  }

  @keyframes logoBefore {
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes logoAfter {
    100% {
      transform: translateX(-100%);
    }
  }
`

const ApostropheTopWrapper = styled.div<{ showLogo: boolean }>`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 30px;

    @keyframes bounceTop {
      0%, 100% {
        top: 45%;
      }
      50% {
        top: 40%;
      }
    }
  
    @keyframes slideUp {
      0% {
        top: 45%;
      }
      100% {
        top: 40%;
        opacity: 0;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 80px;

    @keyframes bounceTop {
      0%, 100% {
        top: 40%;
      }
      50% {
        top: 35%;
      }
    }
  
    @keyframes slideUp {
      0% {
        top: 35%;
      }
      100% {
        top: 25%;
        opacity: 0;
      }
    }
  `}

  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  
  ${props => props.showLogo ? `
    animation: slideUp 1s forwards;
  ` : `
    animation: bounceTop 1.5s ease 3 normal 0s none running;
  `}
`

const ApostropheBottomWrapper = styled.div<{ showLogo: boolean }>`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 30px;

    @keyframes bounceBottom {
      0%, 100% {
        bottom: 45%;
      }
      50% {
        bottom: 40%;
      }
    }
  
    @keyframes slideDown {
      0% {
        bottom: 45%;
      }
      100% {
        bottom: 35%;
        opacity: 0;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 80px;

    @keyframes bounceBottom {
      0%, 100% {
        bottom: 40%;
      }
      50% {
        bottom: 35%;
      }
    }
  
    @keyframes slideDown {
      0% {
        bottom: 35%;
      }
      100% {
        bottom: 18.5%;
        opacity: 0;
      }
    }
  `}

  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  ${props => props.showLogo ? `
    animation: slideDown 1s forwards;
  ` : `
    animation: bounceBottom 1.5s ease 3 normal 0s none running;
  `}
`

const ScrollImageWrapper = styled.div<{ showLogo: boolean }>`
  ${customMedia.lessThan("medium")`
    width: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 50px;
  `}
  
  animation: bounce 1.5s ease infinite normal 2s none running;
  bottom: 25px;
  left: 50%;
  display: ${props => props.showLogo ? 'block' : 'none'};
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0px) translateX(-50%);
    }
    50% {
      transform: translateY(15px) translateX(-50%);
    }
  }
`

const MarqueeWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 3rem;
    margin: 20px 0;
    padding: 2px 0 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 6rem;
    margin: 40px 0;
    padding: 10px 0 0;
  `}

  background-color: #000;
  display: -webkit-flex;
  display: flex;

`

const MarqueeImageWrapper = styled.div<{ width: number }>`
  ${customMedia.lessThan("medium")`
    animation: marquee 15s linear infinite;

    img {
      margin-right: 10px;
      height: 30px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    animation: marquee 30s linear infinite;

    img {
      margin-right: 20px;
      height: 100px;
    }
  `}
  
  display: -webkit-flex;
  display: flex;
`

const TextContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    margin-top: 60px;
  `}
`

const EnTextImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 300px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 500px;
  `}
`

const WindowWrapper = styled.div<{ showWindow: boolean, width: number }>`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;

    div {
      margin: 0 auto;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin-right: 24px;
  `}
`

const WindowContent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  height: 100%;
  width: 100%;
`

const JaText = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 17px;
    padding: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.7rem;
    line-height: 26px;
    padding: 10px 18px;
  `}

  color: #000;
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1px;
  text-align: justify;
`


const SubscribeFormContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 200px;
  `}

  position: relative;
`

const SubscribeForm = styled.form`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  `}

  font-family: 'AcuminVariableConcept';
  font-weight: bold;
`

const SubscribeFormWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    height: 35px;
    margin: 0 auto;
    width: 205px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 65px;
    width: 368px;
  `}
    
  background-color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  align-items: center;
`

const SubscribeInput = styled.input`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    font-variation-settings: 'wght' 650, 'wdth' 45;
    height: 20px;
    letter-spacing: -0.5px;
    padding: 0 5px;
    padding-top: 4px !important;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.6rem;
    font-variation-settings: 'wght' 600, 'wdth' 45;
    height: 40px;
    padding-top: 10px !important;
    padding: 0 5px;
    width: 240px;
  `}

  border: none;
  border-radius: 0;

  ::placeholder {
    color: #cdcecd;
    opacity: 1;
  }
`

const SubscribeButton = styled.button`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    letter-spacing: -0.5px;
    margin-top: 2px;
    margin-right: 3px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    margin-top: 4px;
    margin-right: 6px;
  `}

  color: #fff;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-variation-settings: 'wght' 500, 'wdth' 55;

  :focus {
    outline: 0;
  }
`

const MarketLogoWrapper = styled.div`
  text-align: center;
  font-family: 'EB Garamond', serif;

  ${customMedia.lessThan("medium")`
    margin: 24px 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 18px;
    margin-top: 40px;
  `}
`

const MarketLogoImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 8px auto 0;
    width: 150px;
`}
  ${customMedia.greaterThan("medium")`
    margin: 16px auto 0;
    width: 200px;
  `}
`