import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Window } from '../common/Window'
import { Image } from '../common/Image'
import { Acumin } from '../common/Acumin'

export const Archive = () => {
  return (
    <>
      <Container>
        <Window heightSp={195} widthSp={150} heightPc={390} widthPc={300}>
          <Link to='product/asebi-palo-santo-palo-santo-tray-drawing-by-ue'>
            <WindowContent>
              <ProductImageWrapper width='100%'>
                <Image path='palo-santo/buyitem1.jpg' />
              </ProductImageWrapper>
            </WindowContent>
          </Link>
        </Window>

        <ProductNameSection>
          <ProductName size="small">
            <Link to='product/asebi-palo-santo-palo-santo-tray-drawing-by-ue'>
            asebi Palo Santo + Palo Santo tray drawing by UE
            </Link>
          </ProductName>
          <Link to='product/asebi-palo-santo-palo-santo-tray-drawing-by-ue'>
            <SoldoutText>
              <Acumin white>ON SALE</Acumin>
            </SoldoutText>
          </Link>
        </ProductNameSection>
      </Container>

      <Container>
        <Window heightSp={195} widthSp={150} heightPc={390} widthPc={300}>
          <Link to='product/hinomaru-socks'>
            <WindowContent>
              <ProductImageWrapper width='100%'>
                <Image path='hinomaru-socks/buyitem1.jpg' />
              </ProductImageWrapper>
            </WindowContent>
          </Link>
        </Window>

        <ProductNameSection>
          <ProductName size="small">
            <Link to='product/hinomaru-socks'>
              HINOMARU SOCKS
            </Link>
          </ProductName>
          <Link to='product/hinomaru-socks'>
            <SoldoutText>
              <Acumin white>SOLD OUT</Acumin>
            </SoldoutText>
          </Link>
        </ProductNameSection>
      </Container>

      <Container>
        <Window heightSp={195} widthSp={150} heightPc={390} widthPc={300}>
          <Link to='product/brooklyn-machine-works-gangsta-ucchie-edition'>
            <WindowContent>
              <ProductImageWrapper width='100%'>
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/buyitem1.jpg' />
              </ProductImageWrapper>
            </WindowContent>
          </Link>
        </Window>

        <ProductNameSection margin={50}>
          <ProductName size="small">
            <Link to='product/brooklyn-machine-works-gangsta-ucchie-edition'>
              BROOKLYN MACHINE WORKS GANGSTA “UCCHIE” edition
            </Link>
          </ProductName>
          <SoldoutText>
            <Acumin white>SOLD OUT</Acumin>
          </SoldoutText>
        </ProductNameSection>
      </Container>

      <Container>
        <Window heightSp={195} widthSp={150} heightPc={390} widthPc={300}>
          <Link to='product/studs'>
            <WindowContent>
              <ProductImageWrapper width='100%'>
                <Image path='studs/2.jpg' />
              </ProductImageWrapper>
            </WindowContent>
          </Link>
        </Window>

        <ProductNameSection>
          <ProductName>
            <Link to='product/studs'>
              STuds
            </Link>
          </ProductName>
          <SoldoutText>
            <Acumin white>SOLD OUT</Acumin>
          </SoldoutText>
        </ProductNameSection>
      </Container>

      <Container>
        <Window heightSp={195} widthSp={150} heightPc={390} widthPc={300}>
          <Link to='product/egglife-t'>
            <WindowContent>
              <ProductImageWrapper width='80%'>
                <Image path='egglife-t/8.jpg' />
              </ProductImageWrapper>
            </WindowContent>
          </Link>
        </Window>

        <ProductNameSection>
          <ProductName>
            <Link to='product/egglife-t'>
              EGGLIFE T
            </Link>
          </ProductName>
          <SoldoutText>
            <Acumin white>SOLD OUT</Acumin>
          </SoldoutText>
        </ProductNameSection>
      </Container>
    </>
  )
}


const Container = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 40px 0;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 80px 0;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
`

const WindowContent = styled.div`
  ${customMedia.lessThan("medium")`
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-right: 40px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const ProductImageWrapper = styled.div<{ width: string }>`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: ${props => props.width};
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: ${props => props.width};

    img {
      object-fit: cover;
    }
  `}
`
const ProductNameSection = styled.div<{ margin?: number }>`
  ${customMedia.lessThan("medium")`
    margin: ${props => props.margin ? `${props.margin / 2}px 0 0 20px` : '60px 0 0 20px'};
    width: 150px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: ${props => props.margin ? `${props.margin}px 0 0 20px` : '150px 0 0 40px'};
    width: 350px;
  `}
`

const ProductName = styled.div<{ size?: string }>`
  font-family: 'EB Garamond', serif;
  text-align: center;

  a {
    color: #000;
  }
  
  ${customMedia.lessThan("medium")`
    font-size: ${props => props.size === 'small' ? '1.5rem' : '2.5rem'};

    a {
      margin-left: -10px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: ${props => props.size === 'small' ? '4rem' : '6rem'};
  `}
`

const SoldoutText = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 30px;
    padding-top: 4px;
    width: 80px;

    span {
      font-size: 2rem;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    padding-top: 8px;
    text-align: center;
    width: 200px;

    span {
      font-size: 4rem;
    }
  `}

  background-color: #000;
  text-align: center
`