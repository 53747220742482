import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

export interface OwnProps {
  children: ReactNode
}

export const MyCarousel = (props: OwnProps) => {
  const { children } = props

  const indicatorStyles = {
    background: '#d1d1d1',
    borderRadius: '50%',
    cursor: 'pointer',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 4px'
  }

  return (
    <Carousel
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: '#504e4e' }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }}
      showStatus={false}
      infiniteLoop={true}
      showThumbs={false}
      autoPlay={true}
      interval={10000}
      showArrows={false}
    >
      {children}
    </Carousel>
  )
}